<template>
  <div class="section">
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1 mt-5"
    >
      <b-spinner label="Loading..." />
    </div>
    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="stocks"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Total -->
        <span v-if="props.column.field === 'total'">
          <span>{{ props.row.total | formatAmount }}</span>
        </span>
        <!-- Column Status -->
        <span v-else-if="props.column.field === 'status'">
          <span :class="{'badge badge-light-danger': props.row.deleted_at !== null, 'badge badge-light-success': props.row.deleted_at === null}">
            {{ props.row.deleted_at ? 'Dibatalkan' : 'Sukses' }}
          </span>
        </span>
        <!-- Column: Delivery Numbers -->
        <span v-else-if="props.column.field === 'delivery_numbers'">
          <span>
            {{ props.row.delivery_numbers.map(e => e.delivery_number_note).join(', ') || '-' }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'supplier'">
          <span>
            {{ (props.row.supplier && props.row.supplier.name) || '-' }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'payment_method'">
          <span class="text-capitalize">{{ props.row.payment_method || 'Tempo' }}</span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="$store.commit('stockWarehouse/setIsWarehouse', true), $router.push({ name: 'stock.gudang.detail', params: { id: props.row.uuid }, query: { q: 'warehouse' } })">
                <span>
                  Lihat Detail
                </span>
              </b-dropdown-item>
              <b-dropdown-item v-if="checkPermission('add retur')" @click="returOrder(props.row.uuid)">
                <b-spinner v-if="isLoading" />
                <span>
                  Retur
                </span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!props.row.deleted_at && checkPermission('batalkan produk masuk gudang')" @click="deleteData(props.row.uuid)">
                <span>
                  Batalkan
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows && !isLoading"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getDataProduct"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalRetur
      :is_edit="false"
      :retur-type="'incoming_stock'"
      :results="returData"
    />
    <!-- <ModalIncomeProduct /> -->
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormSelect, VBToggle, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import ModalRetur from '@/components/Retur/Modal/ModalRetur.vue'
// import ModalIncomeProduct from '@/components/Stock/Modal/ModalIncomeProduct.vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormSelect,
    ModalRetur,
    BSpinner,
    // ModalIncomeProduct,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    resultProduct: {
      type: Object,
    },
    getDataProduct: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      columns: [
        {
          label: 'Tanggal',
          field: 'date',
        },
        {
          label: 'Nomor PO',
          field: 'po_so_id',
        },
        // {
        //   label: 'Surat Jalan',
        //   field: 'delivery_number_note',
        // },
        {
          label: 'Total Pembelian',
          field: 'total',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Pemasok',
          field: 'supplier',
        },
        {
          label: 'Metode Bayar',
          field: 'payment_method',
        },
        {
          label: 'No. Surat Jalan',
          field: 'delivery_numbers',
        },
        {
          label: 'Lokasi SKU',
          field: 'warehouse.name',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      stocks: [],
      filter: {
        per_page: 10,
      },
      returData: null,
    }
  },
  watch: {
    resultProduct: {
      handler(value) {
        this.rows = value
        this.stocks = value.data
      },
      deep: true,
    },
    filter: {
      handler(value) {
        this.$emit('filterIncomeProduct', value)
      },
      deep: true,
    },
  },
  methods: {
    async deleteData(uuid) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin untuk menghapus stok gudang ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('stockWarehouse/deleteData', {
            uuid,
          })
            .then(() => {
              this.getDataProduct()
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Stok gudang berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
            })
        }
      })
    },
    returOrder(id) {
      this.isLoading = true
      this.$store.dispatch('stockWarehouse/getData', {
        uuid: `${id}`,
        params: '',
      }).then(result => {
        this.isLoading = false
        this.returData = result.data.data
        this.$store.commit('order/setDataRetur', result.data.data)
        this.$bvModal.show('modal-retur')
      }).catch(err => {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-responsive {
  height: calc(100vh - 235px) !important;
  background-color: #fff;
}
</style>
